<template>
  <div>
    <div class="row justify-content-around">
      <div class="col-sm-12 col-md-12 card">
         <div class="title head">
            <p class="h2" style="color:#558cef">
              ทรัพย์สินจำหน่าย
            </p>
          </div>
          <hr/>
         <!-- search input -->
          <div class="custom-search row justify-content-end">
           
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                /> <b-button>ค้นหา</b-button>
              </div>
            </b-form-group>
          </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"          
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">of {{ props.total }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>     
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'เลขทรัพย์สิน',
          field: 'เลขทรัพย์สิน',
        },
        {
          label: 'ชื่อทรัพย์สิน',
          field: 'ชื่อทรัพย์สิน',
        },
        {
          label: 'ปีงบประมาณ',
          field: 'ปีงบประมาณ',
        },
        {
          label: 'เลขเครื่อง',
          field: 'เลขเครื่อง',
        },
        {
          label: 'สถานที่เก็บ',
          field: 'สถานที่เก็บ',
        },
        {
          label: 'วิธีจำหน่าย',
          field: 'วิธีจำหน่าย',
        },
      ],
      rows: [
        {
          เลขทรัพย์สิน: '2310-001-0001-412-001',
          ชื่อทรัพย์สิน: 'เครื่องคิดเลขขนาดเล็ก',
          ปีงบประมาณ: '2539',
          เลขเครื่อง: 'EN-1234343454',
          สถานที่เก็บ: '',
          วิธีจำหน่าย: 'ขาย',

        },
      ],
      searchTerm: '',
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
